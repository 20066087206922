import { Button } from "@fluentui/react-components"
import { useNavigate, useParams } from "react-router-dom"

export const FormDetailsPage = () => {
    const nav = useNavigate();
    const { id } = useParams();
    const goBack = () => {
        nav(-1);
    }

    return (
        <div>
            <div>Details Page for {id}</div>
            <div><Button onClick={(e) => goBack()}>Go Back</Button></div>
        </div>
    )
}