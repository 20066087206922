import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TextInput, TextMultiline, DropdownInput, DateInput, DateTimeInput, AddressLocation } from "../Components/FormControls";
import { ShowSummary }from "../Components/ShowSummary"; 
import { FormTemplate, findAndConditon, findOrConditon, checkItemCondition } from "../Models/FormModels";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from "@fluentui/react-components";
import { GridInput } from "../Components/GridInput";
import { ChevronLeftRegular, HomeRegular, ChevronRightRegular } from "@fluentui/react-icons";
import { useState } from "react";
import { MultiOptions } from "../Components/MultiOptions";

const SaveForm = (template: FormTemplate) => {
    console.log("%j", template);
}

export const FormView = () => {
    console.log("FormView called");
    var location = useLocation();
    var template = location.state as FormTemplate;
    const [showSubmit, setShowSubmit] = useState(false);
    const [scoreTotal, setScoreTotal] = useState(0);
    const { pageNo } = useParams();
    //const styles = useStyles();
    const navigate = useNavigate();

    var currentPageNumber = parseInt(pageNo ?? "0");
    const currentPage = template.pages[currentPageNumber];
    const canSubmit = currentPageNumber === template.pages.length - 1;
    const goBack = () => {
        navigate(-1);
    }

    const gotoNextPage = () => {
        const nextPageIndex = currentPageNumber + 1;
        EvaluatePageCondition(nextPageIndex);
    }

    const EvaluatePageCondition = (pageIndex: number) => {
        if (pageIndex >= template.pages.length) {
            return
        }
        const nextPage = template.pages[pageIndex];
        if (nextPage.andConditions) {
            if (findAndConditon(template, nextPage.andConditions)) {
                nextPage.fromPageIndex = currentPageNumber;
                console.log(`Goto page ${pageIndex}`);
                navigate(`../${pageIndex}`, { state: template, replace: true });
            }
            else {
                EvaluatePageCondition(++pageIndex);
            }
        }
        else if (nextPage.orConditions) {
            if (findOrConditon(template, nextPage.orConditions)) {
                nextPage.fromPageIndex = currentPageNumber;
                console.log(`Goto page ${pageIndex}`);
                navigate(`../${pageIndex}`, { state: template, replace: true });
            }
            else {
                EvaluatePageCondition(++pageIndex);
            }
        }
        else {
            nextPage.fromPageIndex = currentPageNumber;
            navigate(`../${pageIndex}`, { state: template, replace: true });
        }
    }

    const gotoPreviousPage = () => {
        const previousPage = currentPage.fromPageIndex;
        console.log(`Goto page ${previousPage}`);
        navigate(`../${previousPage}`, { state: template, replace: true, });
    }

    const formItems = () => {
        return currentPage.items.map((item, index) => {
            const showItem = checkItemCondition(template, item);
            if (!showItem) {
                return null;
            }
            const keyId = `P${currentPageNumber}-I${index}`;
            //console.log(`Setting up control ${keyId}, ${item.required}`);
            if (item.inputType === "TextInput") {
                return <TextInput item={item} key={keyId} />
            }
            if (item.inputType === "DateInput") {
                return (<DateInput key={keyId} item={item} useDefaultValue={false} />)
            }
            if (item.inputType === "CurrentDateInput") {
                return (<DateInput item={item} key={keyId} useDefaultValue={true} />)
            }
            if (item.inputType === "CurrentDateTimeInput") {
                return (<DateTimeInput item={item} useDefaultValue={true} key={keyId} />)
            }
            if (item.inputType === "TextMultiline") {
                return (<TextMultiline item={item} key={keyId} />)
            }
            if (item.inputType === "MultiOptions") {
                return (<MultiOptions item={item} key={keyId} />)
            }
            if (item.inputType === "DropdownInput" || item.inputType === "Dropdown") {
                return (<DropdownInput item={item} key={keyId} />)
            }
            if (item.inputType === "DateTimeInput") {
                return (<DateTimeInput key={keyId} item={item} useDefaultValue={false}  />)
            }
            if (item.inputType === "AddressLocation") {
                return (<AddressLocation item={item} key={keyId} />)
            }
            if (item.inputType === "GridInput") {
                return (<GridInput item={item} key={keyId} />)
            }
            if (item.inputType === "ShowSummary") {
                return (<ShowSummary template={template} pageIndex={currentPageNumber} key={keyId} />)
            }
            return <div key={keyId}>No control define {item.inputType}</div>
        });
    }

    console.log(`Loading FormView ${pageNo}`);

    if (template && currentPageNumber >= 0) {
        const submitForm = () => {
            console.log('Submit click');
            CalculateScore();
            setShowSubmit(true);
        }

        const CalculateScore = () => {
            if (template.showScore) {
                var total = 0;
                template.pages.forEach((page) => {
                    page.items.forEach((item) => {
                        total += item.itemScore;
                    })
                })
                setScoreTotal(total);
            }
        }
        type DisplayScoreProps = {
            currentScore: number
        }

        const DisplayScore = (props: DisplayScoreProps) => {
            if (template.showScore) {
                var resultText = "";
                if (template.resultSets) {
                    const result = template.resultSets.find(q => props.currentScore >= q.from && props.currentScore <= q.to);
                    if (result) {
                        resultText = ` - ${result.resultText}`;
                    }
                }
                return (<h2>Score: {props.currentScore}{resultText}</h2>)
            }
            return (<></>);
        }

        return (
            <div className="formConatiner">
                <div className='headerRow'>
                    <h2>{currentPage.pageTitle}</h2>
                </div>
                {formItems()}
                <Dialog open={showSubmit}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>{`Submit ${template.title}`}</DialogTitle>
                            <DialogContent>
                                You are about to submit {template.title} form.<br />
                                {template.showScore && (<DisplayScore currentScore={scoreTotal} />)}
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary" onClick={(e) => setShowSubmit(false)}>Close</Button>
                                </DialogTrigger>
                                <Button appearance="primary">Submit</Button>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
                <div className='footer-button-group'>
                    {currentPageNumber > 0 && (<Button size="large" icon={<ChevronLeftRegular />} onClick={(e) => gotoPreviousPage()} />)}
                    <Button size="large" onClick={(e) => SaveForm(template)}>Save</Button>
                    {canSubmit && (<Button size="large" onClick={(e) => submitForm()}>Submit</Button>)}
                    <Button size="large" icon={<HomeRegular />} onClick={(e) => goBack()} />
                    {(currentPageNumber < template.pages.length - 1) && (<Button size="large" icon={<ChevronRightRegular />} onClick={(e) => gotoNextPage()} />)}
                </div>
            </div>
        )
    }
    else {
        return (<div>No template defined </div>)
    }

}
