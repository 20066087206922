import { BrowserCacheLocation, Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "b044a581-f265-4271-bea5-2b4ca6a79d61",
        authority: "https://login.microsoftonline.com/d07183f5-0384-4262-a031-1aded9e39b41",
        redirectUri: window.location.origin + '/',
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        allowRedirectInIframe: true,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: [ "api://068048fd-3058-4773-8e9c-9d61b84f3226/Execute" ]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const apiConfig = {
    apiEndpoint: "https://intel.api.thefrontline.app"
};