import { FormSummary } from "../Models/FormModels";

export const MyFormsViewModel = () => {
    function loadForms(fromIndex: number) {
        var temp: FormSummary[] = [];
        for (var i = fromIndex; i < fromIndex+50; i++) {
            temp.push({
                id: `id${i}`,
                status: "Draft",
                submittedBy: `Herbert Chung ${i}`,
                submittedOn: new Date(),
                title: `testing ${i}`
            });
        }
        //console.log(`Creating 50 items ${temp.length}`);
        return temp;
    }
    return { loadForms };
}