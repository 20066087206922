import React from 'react';
import ReactDOM from 'react-dom';
import { FluentProvider, teamsDarkTheme, teamsLightTheme, useThemeClassName } from '@fluentui/react-components';
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import { msalConfig } from "./authConfig";
import App from './App';
import { MsalProvider } from '@azure/msal-react';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    console.log(`${event.eventType} called`);

    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      console.log('Set active account');
    }
  });

  var isDarkTheme = false;
  const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
  if (darkThemeMq.matches) {
    isDarkTheme = true;
  }

  function ApplyToBody() {
    const classes = useThemeClassName();
    React.useEffect(() => {
      const classList = classes.split(" ");
      document.body.classList.add(...classList);

      return () => document.body.classList.remove(...classList);

    }, [classes]);
    return null;
  }

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <FluentProvider theme={isDarkTheme ? teamsDarkTheme : teamsLightTheme}>
          <MsalProvider instance={msalInstance}>
            <App />
            <ApplyToBody />
          </MsalProvider>
        </FluentProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root') as HTMLElement
  );
});