import { useEffect, useMemo, useRef, useState } from "react";
import { MyFormsViewModel } from "../ViewModels/MyFormViewModel";
import { AdaptiveCard, HostConfig } from "adaptivecards";
import { } from "adaptivecards"
import { FormSummary } from "../Models/FormModels";
import React from "react";
import { Template } from "adaptivecards-templating";
import InfiniteScroll from "react-infinite-scroll-component";
import { loadTheme } from "@fluentui/react";
import { useNavigate } from "react-router-dom";

const RenderCard = (props: { cardSource: AdaptiveCard, form: FormSummary, template: Template, detailAction: (form: FormSummary) => void}) => {
    const cardWrapperRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        if (!cardWrapperRef || !cardWrapperRef.current || !props.cardSource) return;
        const finalCard = props.template.expand({
            $root: props.form
        });
        props.cardSource.parse(finalCard);
        cardWrapperRef.current.innerHTML = "";
        props.cardSource.render(cardWrapperRef.current);
        console.log(`Rendering card: ${props.form.id}`);
    }, [props.cardSource, cardWrapperRef, props.template]);
    return (<div ref={cardWrapperRef} onClick={(e) => props.detailAction(props.form) }></div>);
}

const MemRenderCard = (props: { card: AdaptiveCard, form: FormSummary, template: Template, detailAction: (form: FormSummary) => void}) => {
    const mem = useMemo(() => {
        return (<RenderCard cardSource={props.card} form={props.form} template={props.template} detailAction={props.detailAction} />)
    }, [props.form])
    return mem;
}

export const MyFormsPage = () => {
    const [viewModel, setViewModel] = useState(MyFormsViewModel());
    const [forms, setForms] = useState<FormSummary[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(`Loadiing from effect {forms.length}`);
        const temp = viewModel.loadForms(forms.length);
        setForms(prev => [...prev, ...temp]);
    }, [viewModel])


    const template = {
        "type": "AdaptiveCard",
        "body": [
            {
                "type": "TextBlock",
                "size": "Medium",
                "weight": "Bolder",
                "text": "${submittedBy}"
            },
            {
                "type": "ColumnSet",
                "columns": [
                    {
                        "type": "Column",
                        "items": [
                            {
                                "type": "Image",
                                "style": "Person",
                                "url": "https://pbs.twimg.com/profile_images/3647943215/d7f12830b3c17a5a9e4afcc370e3a37e_400x400.jpeg",
                                "altText": "${submittedBy}",
                                "size": "Small"
                            }
                        ],
                        "width": "auto"
                    },
                    {
                        "type": "Column",
                        "items": [
                            {
                                "type": "TextBlock",
                                "weight": "Bolder",
                                "text": "${submittedBy}",
                                "wrap": true
                            },
                            {
                                "type": "TextBlock",
                                "spacing": "None",
                                "text": "Created {{DATE(2017-02-14T06:08:39Z,SHORT)}}",
                                "isSubtle": true,
                                "wrap": true
                            }
                        ],
                        "width": "stretch"
                    }
                ]
            }
        ],
        "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
        "version": "1.6"
    };
    const cardTemplate = new Template(template);
    const card = new AdaptiveCard();
    const cardConfig = new HostConfig({
        fontFamily: "Segoe UIm Helvetica Neue, sans-serif",
    });
    card.hostConfig = cardConfig;

    const LoadMore = async () => {
        const temp = viewModel.loadForms(forms.length);
        console.log(`Load more called ${forms.length}, ${temp.length}`);
        var newTemp = [...forms, ...temp];
        setForms(newTemp);
        console.log(`total ${newTemp.length}`);
    }

    const formSelected = (form: FormSummary) => {
        navigate(`${form.id}`);
    }

    return (
        <InfiniteScroll
            dataLength={forms.length} className="card-container"
            next={LoadMore}
            hasMore={true} // Replace with a condition based on your data source
            loader={<p>Loading...</p>} scrollThreshold={1}
            endMessage={<p>No more data to load.</p>}
        >
            {forms.map((form) => {
                return (<MemRenderCard key={form.id} card={card} form={form} template={cardTemplate} detailAction={formSelected} />);
            })
            }
        </InfiniteScroll>
    )
}