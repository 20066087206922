import { Button } from "@fluentui/react-components";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { FormTemplateOptions } from "./Components/FormTemplateOptions";
import { loginRequest } from "./authConfig";

export const HomePage = () => {
  const msal = useMsal();
  
  const loggOff = async () => {
    await msal.instance.logout(loginRequest);
  }

  const doLogin = () => {
    msal.instance.loginPopup(loginRequest);
    //msal.instance.loginRedirect(loginRequest);
  }

  return (

    <div className="container">
      <AuthenticatedTemplate>
        <Button onClick={() => { loggOff() }}>Log Off</Button>
        <FormTemplateOptions />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Button onClick={(e) => doLogin()}>Login</Button>
      </UnauthenticatedTemplate>
    </div>
  );
};


