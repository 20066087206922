import * as React from "react";
import { useMsal } from "@azure/msal-react";
import { FormApi } from "../Services/FormApi";
import { FormCategory } from "../Models/FormModels";
import { Button, Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle, DrawerProps, Link } from "@fluentui/react-components";
import Iframe from "react-iframe";



export const FormTemplateOptions = () => {

    type DrawerType = Required<DrawerProps>["type"];

    const msal = useMsal();
    const [options, setOptions] = React.useState<FormCategory[]>([]);
    React.useEffect(() => {
        const fetchData = async () => {
            const api = new FormApi(msal);
            const data = await api.getFormOptions("reports-desktop");
            setOptions(data.categories);
        }
        fetchData();
    }, [msal]);

    const [isOpen, setIsOpen] = React.useState(false);

    const [iframeSrc, setIFrameSrc] = React.useState('');

    const linkClick = (link: string) => {
        setIFrameSrc(link);
        setIsOpen(true);
    }
    const [type, setType] = React.useState<DrawerType>("overlay");

    return (<div className="formConatiner">
        <Drawer
            type={type}
            position={"end"}
            separator
            size={"medium"}
            open={isOpen}
            onOpenChange={(_, { open }) => setIsOpen(open)}
        >
            <DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            onClick={() => setIsOpen(false)}
                        />
                    }
                >
                    Default Drawer
                </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody>
                <Iframe url={iframeSrc} width="100%" height="100%" />
            </DrawerBody>
        </Drawer>

        {
            options.map((category, index) => {
                return (<div key={index.toString()} ><h2>{category.name}</h2>
                    {
                        category.items.map((item, index2) => {
                            return (<div key={`${index}-${index2}`}><Button onClick={() => linkClick(`NewForm/${item.reportId}`)}>{item.displayName}</Button></div>)

                            //    return (<div key={`${index}-${index2}`}><Link href={`NewForm/${item.reportId}`}>{item.displayName}</Link></div>) 
                        })
                    }

                </div>);
            })
        }



    </div>);
}
