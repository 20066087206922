
import { Divider, Field } from "@fluentui/react-components";
import { FormTemplate, FormPage, findAndConditon, findOrConditon } from "../Models/FormModels"

const formItems = (template: FormTemplate, page: FormPage) => {
    return page.items.map((item) => {
        if (item.andConditions) {
            if (!findAndConditon(template, item.andConditions))
                return null;
        } else if (item.orConditions) {
            if (!findOrConditon(template, item.orConditions))
                return null;
        }
        return (
            <div className="item-container" key={item.itemId}>
                <Field label={item.title} size="large">
                    <div>{item.value}</div>
                </Field>
            </div>
        )
    });
}

export const ShowSummary = (props: { template: FormTemplate, pageIndex: number }) => {
    console.log(`summary page index ${props.pageIndex}`);

    return (<div>
        {props.template.pages.map((page, index) => {
            if (index === props.pageIndex) return null;
            if (page.andConditions) {
                if (!findAndConditon(props.template, page.andConditions))
                    return null;
            } else if (page.orConditions) {
                if (!findOrConditon(props.template, page.orConditions))
                    return null;
            }
            return (
                <div key={`Page${index}`}>
                    <div className="headerRow"><h3>{page.pageTitle}</h3></div>
                    <div className='defaultDivGap'>
                        {formItems(props.template, page)}
                    </div>
                    <div style={{ marginTop: "10px" }}><Divider inset={false} /></div>
                </div>
            )
        })}</div>)
}
