import {
    Button, Field, Dialog, DialogSurface, DialogBody, DialogTitle, DialogContent,
    DialogActions, DialogTrigger, Textarea
} from "@fluentui/react-components";
import { FormItem } from "../Models/FormModels";
import { useState } from "react";

export const NoteDialogView = (props: { item: FormItem, closeDialog: ()=>void}) => {
    const [note, setNote] = useState<string | undefined>(props.item.note);

    const updateNote = () => {
        props.item.note = note;
        props.closeDialog();
    }

    return (
        <Dialog open={true}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Note</DialogTitle>
                    <DialogContent> 
                        <Field label={props.item.title} size="large">
                        <Textarea defaultValue={props.item.note ?? ''} size="large" resize="vertical"
                                  rows={40} onChange={(e, d) => setNote(d.value)} />
                        </Field>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" onClick={() => props.closeDialog()}>Cancel</Button>
                        </DialogTrigger>
                        <Button appearance="primary" onClick={() => updateNote()}>Update</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}