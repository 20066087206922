import { ToggleButton, Field } from "@fluentui/react-components";
import { FormItem, OptionItem } from "../Models/FormModels";
import { useState } from "react";
import { ItemFooterButtonSet } from "./FormControls";

export const MultiOptions = (props: {item: FormItem}) => {
    const [options, setOptions] = useState<OptionItem[]>(props.item.options ?? []);
    if (!props.item.options) {
        return (<div>Invalid MultiOptions control: options is null</div>)
    }

    const buttonClicked = (value: string | undefined) => {
        props.item.value = value;
        if (options) {
            setOptions([...options])
        }
    }

    return (
        <div className='item-container'>
            <Field label={props.item.title} size="large">
                <div className="toggleButtonGroup">
                    {
                        options.map((option, index) => {
                            const isSelected = option.optionText === props.item.value;
                            //console.log(`${option.optionText} selected: ${isSelected}`)
                            return (
                                <ToggleButton checked={isSelected} size="large" key={index} value={option.optionText}
                                    className={isSelected ? 'toggleButtonSelected' : undefined}
                                    onClick={(e) => buttonClicked(option.optionText)} >{option.optionText}</ToggleButton>
                            )
                        })
                    }
                </div>
            </Field>
            <ItemFooterButtonSet item={props.item} />
        </div>
    );
}