import { IMsalContext } from "@azure/msal-react";
import { FormTemplate, FormOptions } from "../Models/FormModels"
import { apiConfig, loginRequest } from "../authConfig";

export class FormApi {
    private msal: IMsalContext;

    constructor (msal: IMsalContext ) {
        this.msal = msal;
      }

    async getForm(docType: string) {
        var result = await this.msal.instance.acquireTokenSilent(loginRequest);
        var url = `${apiConfig.apiEndpoint}/api/IncidentAssessments/Templates/${docType}`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${result.accessToken}`
            })
        });
        const data: FormTemplate = await response.json();
        return data;
    }

    async getFormOptions(reportId: string) {
        var result = await this.msal.instance.acquireTokenSilent(loginRequest);
        var url = `${apiConfig.apiEndpoint}/ReferenceData/ReportCategories?reportId=${reportId}`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${result.accessToken}`
            })
        });
        const data: FormOptions = await response.json();
        return data;
    }
}
